import { gt as greaterThan, lt as lessThan, orderBy, uniqBy } from 'lodash'

import {
  MapIndex,
  Lot,
  CalendarDateIndex,
  SatelliteProviders,
  CalendarDateIndexWithMultipleProviders,
  CalendarDate,
  MapIndexInfo,
  EmergenceDaysActivationIndices,
  Selects,
} from 'types'

const serializeCalendarDates = (
  calendarDates: CalendarDateIndexWithMultipleProviders[],
  selectedSatellite: SatelliteProviders,
  isAllOptionsSelected: boolean,
) => {
  return calendarDates.map(calendarDate => {
    if (isAllOptionsSelected) {
      return {
        ...calendarDate,
        mapIndexInfo: calendarDate.mapIndexInfo[0],
      }
    }
    return {
      ...calendarDate,
      mapIndexInfo: {
        ...calendarDate.mapIndexInfo.filter(info => info.provider === selectedSatellite)[0],
      },
    }
  })
}

const filterCalendarDatesBySelectedProvider = (
  calendarDates: CalendarDateIndexWithMultipleProviders[],
  selectedSatellite: SatelliteProviders,
  isAllOptionsSelected: boolean,
) => {
  if (isAllOptionsSelected) return calendarDates
  return calendarDates
    .map(calendarDate => ({
      date: calendarDate.date,
      mapIndexInfo: calendarDate.mapIndexInfo.filter(
        mapIndex => mapIndex.provider === selectedSatellite,
      ),
    }))
    .filter(calendarDate => !!calendarDate.mapIndexInfo.length)
}

const removeDuplicatedDatesByIndex = (
  calendarDates: CalendarDateIndexWithMultipleProviders[],
  selectedSatellite: SatelliteProviders,
) => {
  const isAllOptionsSelected = selectedSatellite === 'ALL'

  const filteredDates = filterCalendarDatesBySelectedProvider(
    calendarDates,
    selectedSatellite,
    isAllOptionsSelected,
  )
  const serializedCalendarDates = serializeCalendarDates(
    filteredDates,
    selectedSatellite,
    isAllOptionsSelected,
  )

  const orderedByDate = orderBy(
    serializedCalendarDates,
    ['date', 'mapIndexInfo.isAvailable'],
    ['desc', 'desc'],
  )
  const calendarDatesWithoutDuplicates = uniqBy(orderedByDate, 'date')

  return calendarDatesWithoutDuplicates
}

const getCalendarDateByProvider = (
  selectedSatellite: SatelliteProviders,
  selectedIndicator: MapIndex,
  mapUrls?: CalendarDate['mapUrls'],
): MapIndexInfo | undefined => {
  const isAllOptionsSelected = selectedSatellite === 'ALL'

  return (
    isAllOptionsSelected
      ? mapUrls?.[selectedIndicator]?.[0]
      : mapUrls?.[selectedIndicator]?.find(
          calendarDateData => calendarDateData.provider === selectedSatellite,
        )
  ) as MapIndexInfo | undefined
}

const getImageUrl = (
  lot: Lot,
  selectedMapIndex: MapIndex,
  selectedDate: string,
  selectedSatellite: SatelliteProviders,
) => {
  const selectedLotDate = lot.riceLot.calendar.find(date => date.date === selectedDate)

  const calendarDateData = getCalendarDateByProvider(
    selectedSatellite,
    selectedMapIndex,
    selectedLotDate?.mapUrls,
  )

  if (!calendarDateData?.isAvailable) return undefined

  return calendarDateData.url ?? undefined
}

const getEmergenceDaysActivationIndices = (lots: Lot[]): EmergenceDaysActivationIndices => {
  return lots.reduce(
    (emergenceDaysActivation, lot: Lot) => {
      if (!lot.riceLot.calendar.length) return emergenceDaysActivation

      const newestDateIndex = lot.riceLot.calendar.length - 1
      const lotWaterEmergenceDays =
        lot.riceLot.calendar[newestDateIndex].mapUrls?.water?.[0].emergenceDaysActivation
      const lotGrainHumidityEmergenceDays =
        lot.riceLot.calendar[newestDateIndex].mapUrls?.grainHumidity?.[0].emergenceDaysActivation
      const lotWeedsEmergenceDays =
        lot.riceLot.calendar[newestDateIndex].mapUrls?.weeds?.[0].emergenceDaysActivation
      const lotEmergenceDays =
        lot.riceLot.calendar[newestDateIndex].mapUrls?.emergence?.[0].emergenceDaysActivation

      if (
        !lotWaterEmergenceDays &&
        !lotGrainHumidityEmergenceDays &&
        !lotWeedsEmergenceDays &&
        !lotEmergenceDays
      )
        return emergenceDaysActivation

      if (
        greaterThan(lotWaterEmergenceDays, emergenceDaysActivation.water) &&
        lotWaterEmergenceDays
      ) {
        // eslint-disable-next-line no-param-reassign
        emergenceDaysActivation.water = lotWaterEmergenceDays
      }

      if (
        (emergenceDaysActivation.grainHumidity === 0 ||
          lessThan(lotGrainHumidityEmergenceDays, emergenceDaysActivation.grainHumidity)) &&
        lotGrainHumidityEmergenceDays
      ) {
        // eslint-disable-next-line no-param-reassign
        emergenceDaysActivation.grainHumidity = lotGrainHumidityEmergenceDays
      }

      if (lotWeedsEmergenceDays) {
        // eslint-disable-next-line no-param-reassign
        emergenceDaysActivation.weeds = lotWeedsEmergenceDays
      }
      if (lotEmergenceDays) {
        // eslint-disable-next-line no-param-reassign
        emergenceDaysActivation.emergence = lotEmergenceDays
      }

      return emergenceDaysActivation
    },
    { water: 0, grainHumidity: 0, weeds: 0, emergence: 0 },
  )
}

const dateHasUrls = (calendarDate?: CalendarDateIndex) => {
  if (!calendarDate?.date) return false

  return !!calendarDate.mapIndexInfo.url
}

const filterLotsByConditions = (
  lots: Lot[],
  selectedCropId?: number,
  selectedVarietyId?: number | Selects.NO_VARIETY,
) => {
  if (!selectedCropId && !selectedVarietyId) return lots

  if (selectedVarietyId) {
    const filteredLotsWithoutVarietyByCopId = lots.filter(
      lot => lot.crop.id === selectedCropId && !lot.variety,
    )
    if (selectedVarietyId === Selects.NO_VARIETY) return filteredLotsWithoutVarietyByCopId

    return lots.filter(lot => lot.variety?.id === selectedVarietyId)
  }

  return lots.filter(lot => lot.crop.id === selectedCropId)
}

export const LotsUtils = {
  removeDuplicatedDatesByIndex,
  getImageUrl,
  dateHasUrls,
  getEmergenceDaysActivationIndices,
  getCalendarDateByProvider,
  filterLotsByConditions,
}
